<template>
  <div class='flex flex-row lg:flex-col w-full lg:w-64'>
    <div class='bg-gray-50 p-2 w-1/2 lg:w-full'>
      <img :src='showingPoster.thumbnailUrl' class='h-48 w-60 object-cover' style='object-position: 50% 0%;'>
    </div>
    <div class='bg-white p-4 h-32 w-1/2 lg:w-full'>
      <h5 class='text-xs mb-2 font-semibold uppercase' style='color: #C04A77;'>{{ showingPoster.posterNo }}</h5>
      <div class='mb-2 text-sm'>
        <v-clamp 
          class='whitespace-pre-line'
          :max-lines='3'>
           {{ showingPoster.title }}
        </v-clamp>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from 'vue-clamp'

export default {
  name: 'PosterPreview',
  components: {
    VClamp,
  },
  props: [
    'showingPoster'
  ]
}
</script>
