<template>
  <div class='flex flex-col lg:flex-row gap-y-8 relative'>
    <div class='w-full lg:w-1/5 h-12 lg:h-96 px-4 lg:py-16 flex flex-row lg:flex-col justify-between items-center text-gray-50'
      style='background: linear-gradient(151.84deg, #875973 10.82%, #714B4B 98.95%);'>
      <div class='font-semibold text-xl lg:text-2xl'>
        Posters
      </div>
      <router-link :to='{ name: "Posters" }'
        class='border px-2 py-1 text-xs uppercase'>
        Read More
        <arrow-narrow-right-icon class='inline-block ml-1' size='1.2x' />
      </router-link>
    </div>
    <div class='lg:absolute flex flex-col lg:flex-row gap-y-4 lg:gap-x-8 lg:overflow-x-auto h-full poster-preview-list-box'>
      <div v-for='(posterPreview, index) in posterPreviews'
        :key='`poster-preview-${index}`'
        class ='flex flex-col my-auto border cursor-pointer relative'
        @click='openDetail(posterPreview)'>
        <div class='hidden lg:flex flex-col justify-center items-center absolute top-2 left-2 bg-gray-500 opacity-50 hover:opacity-0 h-48 w-60'>
          <zoom-in-icon size='3x' class='text-white font-bold' />
        </div>
        <posters-preview-card
          :showing-poster='posterPreview' />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions }             from 'vuex'
import { ZoomInIcon, ArrowNarrowRightIcon } from '@vue-hero-icons/outline'
import PostersPreviewCard                   from '@/components/posters/PostersPreviewCard.vue'

export default {
  name: 'PostersPreview',
  components: {
    ZoomInIcon,
    ArrowNarrowRightIcon,
    PostersPreviewCard,
  },
  computed: {
    ...mapState('posters', [
      'posters',
    ]),
    posterPreviews () {
      let randomOffset = Math.floor(Math.random() * (this.posters.length - 3))
      return this.posters.slice(randomOffset, randomOffset + 4)
    }
  },
  methods: {
    ...mapActions('posters', [
      'getPosters'
    ]),
    openDetail (poster) {
      this.$router.push({ name: 'Poster', query: { poster_id: poster.id } })
    },
  },
  mounted () {
    this.getPosters()
  },
}
</script>


<style type='scss'>
  .poster-preview-list-box {
    left: 0%;
    width: 100%;
  }

  @media (min-width: 1024px) {
    .poster-preview-list-box {
      left: 30%;
      width: 70%;
    }
  }
</style>