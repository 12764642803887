<template>
  <div>
    <welcome-conference-banner background-color='#F9F6F9'/>
    <welcome-sponsors-carousel class='mt-12' />
    <sessions-currently-live 
      class='mt-12'
      title='GBCC 2022' />
    <posters-preview class='mt-24' />
    <on-load-modal
      v-for='(onLoadModal, index) in onLoadModals'
      :key='`onLoadModal-${index}`'
      :onLoadModal='onLoadModal' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OnLoadModal             from '@/components/OnLoadModal.vue'
import WelcomeConferenceBanner from '@/components/welcome/WelcomeConferenceBanner.vue'
import SessionsCurrentlyLive   from '@/components/SessionsCurrentlyLive.vue'
import PostersPreview          from '@/components/posters/PostersPreview.vue'
import WelcomeSponsorsCarousel from '@/components/welcome/WelcomeSponsorsCarousel.vue'

export default {
  name: 'Main',
  components: {
    OnLoadModal,
    WelcomeConferenceBanner,
    SessionsCurrentlyLive,
    PostersPreview,
    WelcomeSponsorsCarousel,
  },
  computed: {
    ...mapGetters('events', [
      'onLoadModals'
    ])
  },
}
</script>
